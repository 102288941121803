<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-暑期課程學分費補助核定清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select
            :options="years"
            v-model="searchYear"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="success" @click="batchApprove">批次核定</b-button>
      </div>
    </section>
    
    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item.name || item"
      >
        {{ item.name || item }}
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="cursor: pointer;padding-top:3px;">
        {{ i + 1 }}
      </div>
      <div :title="item.status" style="padding:2px 0">
        <input 
          type="checkbox" 
          class="mr-2"
          v-model="batchSlt"
          :value="item.SeqNo"
          :disabled="item.StatusName == '預審通過' ? false : true" />
        <b-button 
          variant="success" 
          size="sm" 
          @click="assessPas(item)" 
          :disabled='!isPassable(item)'
          >通過</b-button>
        <!-- <b-button
          variant="danger"
          size="sm"
          @click="denyPas(item)"
          :disabled="!isPassable(item)"
          >退件</b-button
        > -->
      </div>
      <div :title="item.servicenow" style="padding:2px 0">
        <b-button variant="primary" size="sm" @click="openPrintPage(item)"
          >檢視</b-button
        >
      </div>
      <div
        :style="
          item.StatusName == '預審中'
            ? 'color:#43944a'
            : item.StatusName == '預審退回'
            ? 'color:red'
            : item.StatusName == '完成核定'
            ? 'color:rgb(18 107 214)'
            : ''
        "
        :id="`itemOne${i}`"
      >
        {{ item.StatusName || "" }}
      </div>

      <!--<b-tooltip
        variant="primary"
        :target="`itemOne${i}`"
        placement="top"
        style="text-align:left"
      >
        完成核定: 於民國XXX年XX月XX日前函文-->
        <!-- <div v-if="item[column.key] == '完成核定'" style="text-align: left;">
          完成核定 <br />請於民國XXX年XX月XX日前函文
        </div>
        <div
          v-else-if="item[column.key] == '退回申請'"
          style="text-align: left;"
        >
          退回申請 <br />原因:
        </div> -->
      <!-- </b-tooltip> -->
      
      <div>{{ item.ApplyDate ? $twDate(item.ApplyDate) : "" }}</div>
      <div>{{ item.PassDate ? $twDate(item.PassDate) : "" }}</div>
      <div>{{ item.SchoolName }}</div>
      <div>{{ item.BYear }}</div>
      <div>{{ item.ApproveDate ? $twDate(item.ApproveDate) : "" }}</div>
      <div>{{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : $formatPrice(item.Apply_Amt) }}</div>
      <div style="padding:0">
        <b-input
          placeholder="核定文號"
          v-model="item.ApproveNumber"
          @change="saveData"
        ></b-input>
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="passDenyModal"
        :title="`${passDenyModel.editKind == 1 ? '通過預審' : '預審退件'}`"
        size="md"
        :header-bg-variant="`${passDenyModel.editKind == 1 ? 'success' : 'danger'}`"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="培育學校">
            <b-input v-model="passDenyModel.schoolName" disabled></b-input>
          </b-input-group>
          <b-input-group prepend="函文期限" class="mt-2" v-show="passDenyModel.editKind == 1">
            <calendarInput v-model="passDenyModel.passDeadline" />
          </b-input-group>
          <b-input-group prepend="退件原因" class="mt-2" v-show="passDenyModel.editKind == 2">
            <b-form-textarea
              id="textarea"
              placeholder="退件原因輸入"
              rows="3"
              max-rows="6"
              v-model="passDenyModel.denyReason"
            ></b-form-textarea>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('passDenyModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveData"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-modal
        id="approveModel"
        title="批次核定"
        size="sm"
      >
        <div>
          <b-input-group prepend="核定日期">
            <calendarInput
              v-model="batchApproveModel.approveDate"
              style="height:40px;text-align:center"
            />
          </b-input-group>
          <b-input-group prepend="核定文號">
            <b-input v-model="batchApproveModel.approveNumber"></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('approveModel')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="doBatchApprove"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-modal
        id="editModal"
        title="批次核定"
        size="md"
        header-bg-variant="success"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="培育學校">
            <b-input v-model="passSchoolName" disabled></b-input>
          </b-input-group>
          <b-input-group prepend="核定文號" class="mt-2" v-show="editKind == 1">
            <b-input
              v-model="passNumber"
              placeholder="請在此輸入核定文號"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button variant="primary" class="float-right mr-1">確定</b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import calendarInput from "@/components/localCalendar.vue";
import TabThreeSubsidyApplicationEditSemesterTwoVue from "../budgetapplyrural/TabThreeSubsidyApplicationEditSemesterTwo.vue";
// import { countryObject } from "@/constant.js";

const headersOne = [
  "序",
  "核定",
  "暑期學分課程補助申請表",
  "審核狀態",
  "提出日期",
  "審核通過日期",
  "培育學校",
  "年度",
  "核定日期",
  "申請(核定)金額",
  "核定文號",
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1905;
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "downarea",
  data() {
    return {
      headersOne,
      batchSlt: [],
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchSchool: null,
      searchYear: new Date().getFullYear() - 1911,

      passDenyModel: {
        editKind: 1,
        schoolName: "",    
        denyReason: "",
        passDeadline: "",
      },
      batchApproveModel: {
        approveDate: "",
        approveNumber: "",
      },

      editKind: 1,

      passSchoolName: "台灣大學",
      passNumber: "",
    };
  },
  components: { calendarInput },
  computed: {},

  methods: {
    saveData() {
      this.$bvToast.toast(`儲存成功`, {
        title: "系統資訊",
        autoHideDelay: 5000,
        variant: "success",
      });
    },
    isPassable(i) {
      return i.Status == 1;
    },
    async assessPas(item) {
      const url = `Budgeting/SubmitSummerPass?seqno=${item.SeqNo}`;
      try {
        let res = await window.axios.put(url);
        if(res){
          this.$bvToast.toast(`審核通過`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          });
        }else{
          this.$bvToast.toast(`提交失敗`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async denyPas(item) {
      console.log(item);
      this.passDenyModel.editKind = 2;
      this.passDenyModel.schoolName = item.SchoolName;
      this.$bvModal.show("passDenyModal");
      
      /* const url = `Budgeting/SubmitSummerDeny?seqno=${item.SeqNo}`;
      try {
        let res = await window.axios.put(url);
        if(res){
          this.$bvToast.toast(`退件成功`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          });
        }else{
          this.$bvToast.toast(`退件失敗`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } */
    },
    batchApprove() {
      if(this.batchSlt.length == 0){
        this.$bvToast.toast(`未勾選核定對象`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      else{
        this.$bvModal.show("approveModel");
      }
    },
    async doBatchApprove(){
      const obj = {
        SeqNos: this.batchSlt,
        ApproveDate: this.batchApproveModel.approveDate,
        ApproveNumber: this.batchApproveModel.approveNumber
      };

      const url = `Budgeting/SetSummerApprove`;
      try {
        let res = await window.axios.put(url, obj);
        if(res){
          this.$bvToast.toast(`資料儲存成功`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          });
          this.$bvModal.hide('approveModel')
          this.getData();
        }else{
          this.$bvToast.toast(`儲存失敗`, {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "danger",
          });
        }
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage(i) {
      let url = "";
      url = `/#/docsummer?seqno=${i.SeqNo}&schoolcode=${i.SchoolCode}&byear=${i.BYear}&term=1`;
      window.open(url);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sortOne(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headersOne.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.getData();
    },
    async getData() {
      this.batchSlt = [];
      // odata3 qs top&skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      // const orderBy = this.orderBy;
      const obj = { top, skip };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");

      let url = "";
      url = `Budgeting/GetSummerList?byear=${this.searchYear}&term=1&category=T`;

      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }
      
      if (Boolean(this.searchSchool)) {
        //this.searchSchool = this.schoolObject[0].value;
        url = `${url}&schoolcode=${this.searchSchool}`;
      }
      
      try {
        let {Items} = await window.axios.get(url);
        if(Items != null){
          Items = Items.map((s) => {
            s.StatusName = s.Status == 4 ? "完成核定" : s.StatusName;
            //s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text: "";
            return s;
          });
          this.items = JSON.parse(JSON.stringify(Items));
        }
      } catch (e) {
        console.log(e);
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    // async getMajor() {
    //   const arr = await window.axios.get("SysCode/Get?category=major");
    //   this.majorObject = arr.map((s) => ({
    //     value: s.Display,
    //     text: s.Display,
    //   }));
    //   this.majorObject.unshift({ value: null, text: "全部" });
    // },
  },
  async mounted() {
    this.getAllSchool();
     this.getData();
  },

  watch: {
    passNumber(v) {
      let value = `${v}`;
      if (v.length > 9) {
        value = value.substring(1, 10);
      }
      const result = value;
      this.$nextTick(() => (this.passNumber = result));
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 80px 150px 190px 140px 160px 250px 180px 120px 120px 140px 190px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 220px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
::v-deep#editModal___BV_modal_header_ {
  color: #fff;
}
</style>
