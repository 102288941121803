<template>
  <section class="dtc-main-section mt-2 mr-2">
    <EquipmentPurchases></EquipmentPurchases>
    <section class="search-block">
      <h5 class="big-title">
        經費申請
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="getData"
            :disabled="healthCodeCheck"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select
            :options="years"
            v-model="searchYear"
            @change="getData"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="學期">
          <b-select
            :options="[
              { value: 1, text: '第一學期(上學期)' },
              { value: 2, text: '第二學期(下學期)' },
            ]"
            v-model="searchSemester"
            @change="getData"
          ></b-select>
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>

    <header
      class="dtc-grid-header my-dark"
      :class="searchSemester == 2 ? 'dtc-grid-header-semester2' : ''"
    >
      <div
        v-for="(item, i) in searchSemester == 1 ? headersOne : headersTwo"
        :key="`headers${i}`"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header content"
      v-for="(item, i) in items"
      :key="`content${i}`"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
      :class="searchSemester == 2 ? 'dtc-grid-header-semester2' : ''"
    >
      <div style="cursor: pointer;padding-top:3px;">
        {{ i + 1 }}
      </div>
      <div style="padding:2px 0">
        <b-button
          variant="warning"
          size="sm"
          @click="openModal(item)"
          :disabled="
            item.StatusName == '未申請' || item.StatusName == '已退回'
              ? false
              : true
          "
          >提出</b-button
        >
      </div>
      
      <!-- <div style="padding:2px 0" v-if="searchSemester == 1">
        <b-button variant="primary" size="sm" @click="openSingleSubEdit(item)"
          >編輯</b-button
        >
      </div> -->
      <div style="padding: 2px 0">
        <b-button
          variant="primary"
          size="sm"
          @click="openSubsidyEditprinted(item)"
          :disabled="item.Status == 7"
          >編輯</b-button
        >
      </div>
      <div style="padding:2px 0">
        <b-button variant="primary" size="sm" @click="openSubsidyEdit(item)"
        :disabled="item.StatusName === '預審完成'"
          >編輯</b-button
        >
      </div>
      <!--<div style="padding:2px 0" v-if="searchSemester == 1">
        <b-button variant="success" size="sm" @click="openEquipPurchase(item)"
          >編輯</b-button
        >
      </div>-->
      <div
        :style="
          item.StatusName == '預審中'
            ? 'color:#43944a'
            : item.StatusName == '已退回'
            ? 'color:red'
            : item.StatusName == '預審完成'
            ? 'color:rgb(18 107 214)'
            : item.StatusName == '完成核定'
            ? 'color:rgb(18 107 214)'
            : ''
        "
        :id="`itemOne${i}`"
      >
        {{ item.StatusName || "" }}
      </div>
      <b-tooltip
        :target="`itemOne${i}`"
        placement="top"
        :variant="item.StatusName == '完成核定' ? 'primary' : 'danger'"
        style="text-align:left;min-width:300px"
        v-if="item.StatusName == '完成核定' || item.StatusName == '已退回'"
      >
        <div v-if="item.StatusName == '完成核定'" style="text-align: left;">
          完成核定<br />請於{{
            item.PerAuditComment ? $twDate4(item.PerAuditComment) : ""
          }}前函文
        </div>
        <div v-else style="text-align: left;">
          退回申請<br />原因:{{ item.RejectComment || "" }}
        </div>
      </b-tooltip>
      <div style="padding:0" v-if="item.StatusName == '完成核定'">
        <b-input
          v-model.trim="item.ApplyNumber"
          placeholder="公文號"
          @change="saveApplyNumberData(item)"
          style="text-align: center;height:40px"
        ></b-input>
      </div>
      <div v-else>{{ item.ApplyNumber || "" }}</div>
      <!-- <div
        style="padding:0;overflow: initial;"
        v-if="item.StatusName == '完成核定'"
      >
        <calendarInput
          v-model="item.applyDate"
          style="height:40px;text-align:center"
          :nowDataIndex="`${i}`"
          @update2="saveApplyDateData"
          :key="`calendarInput$${compontCalderKey}`"
        />
      </div>
      <div v-else>
        {{ item.applyDate ? $twDate(item.applyDate) : "" }}
      </div> -->
      <div :title="item.ApplyDate">{{ $twDate(item.ApplyDate) || "" }}</div>
      <div :title="item.school">{{ item.school || "" }}</div>
      <div :title="item.BYear">{{ item.BYear || "" }}</div>
      <div :title="item.Term">{{ item.Term == 1 ? "一" : item.Term == 2 ? "二" : "" }}</div>
      <div :title="item.ApproveDate">{{ item.ApproveDate ? $twDate(item.ApproveDate) : "" }}</div>
      <!-- <div :title="item.Apply_Amt">{{ item.Apply_Amt ? $formatPrice(item.Apply_Amt) : "" }}</div> -->
      <div :title="item.Approve_Amt">{{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : "" }}</div>
      <div :title="item.ApproveNumber">{{ item.ApproveNumber || "" }}</div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        title="發文日期及文號"
        size="md"
        header-bg-variant="warning"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="發文日期" class="mt-2">
            <date-picker-tw
              :time="ApplyDate"
              @update="(t) => (ApplyDate = t)"
            ></date-picker-tw>
          </b-input-group>
          <b-input-group prepend="發文文號" class="mt-2">
            <b-input
              v-model="ApplyNumber"
              placeholder="請在此輸入核定文號"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveApplyData"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>

  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import EquipmentPurchases from "./EquipmentPurchases";
import calendarInput from "@/components/localCalendar.vue";
// import { countryObject } from "@/constant.js";

const headersOne = [
  { name: "序", key: "", sortDesc: null },
  { name: "提出申請", key: "", sortDesc: null },
  { name: "印領清冊", key: "", sortDesc: null },
  // { name: "學生申請清冊", key: "", sortDesc: null },
  { name: "待遇補助經費申請表", key: "", sortDesc: null },
  //{ name: "設備採購品目表", key: "", sortDesc: null },
  { name: "審核狀態", key: "Status", sortDesc: null },
   { name: "公文號", key: "ApplyNumber", sortDesc: null },
   { name: "發文日期", key: "ApplyDate", sortDesc: null },
  //{ name: "提出日期", key: "ApplyDate", sortDesc: null },
  //{ name: "審核通過日期", key: "ApproveDate", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "年度", key: "BYear", sortDesc: null },
  { name: "學期", key: "Term", sortDesc: null },
  //{ name: "申請金額", key: "Apply_Amt", sortDesc: null },
  { name: "核定日期", key: "ApproveDate", sortDesc: null },
  { name: "核定金額", key: "Approve_Amt", sortDesc: null },
  { name: "核定文號", key: "ApproveNumber", sortDesc: null },
];

const headersTwo = [
  { name: "序", key: "", sortDesc: null },
  { name: "提出申請", key: "", sortDesc: null },
  { name: "印領清冊", key: "", sortDesc: null },
  { name: "待遇補助經費申請表", key: "", sortDesc: null },
  { name: "審核狀態", key: "Status", sortDesc: null },
   { name: "公文號", key: "ApplyNumber", sortDesc: null },
   { name: "發文日期", key: "ApplyDate", sortDesc: null },
  //{ name: "提出日期", key: "ApplyDate", sortDesc: null },
  //{ name: "審核通過日期", key: "ApproveDate", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "年度", key: "BYear", sortDesc: null },
  { name: "學期", key: "Term", sortDesc: null },
  //{ name: "申請金額", key: "Apply_Amt", sortDesc: null },
  { name: "核定日期", key: "ApproveDate", sortDesc: null },
  { name: "核定金額", key: "Approve_Amt", sortDesc: null },
  { name: "核定文號", key: "ApproveNumber", sortDesc: null },
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1905;
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "TabOneSubsidyApplication",
  data() {
    return {
      headersOne,
      headersTwo,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      orderBy2: [],
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchSchool: null,
      searchYear: store.searchYear?store.searchYear: new Date().getFullYear() - 1911 - 1,
      searchSemester: 1,
      compontCalderKey: 0,
      ApplyNumber: "",
      ApplyDate: this.$twDate2(this.$moment(new Date()).format("YYYY-MM-DD")),
      SeqNo: null,
    };
  },
  components: { EquipmentPurchases, calendarInput },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const schoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      return schoolArr.includes(loginHealthCode);
    },
  },
  methods: {
    async saveApplyData(item) {
      const obj = {
        SeqNo: this.SeqNo,
        Status: 1,
        ApplyNumber: this.ApplyNumber,
        ApplyDate: this.$usDate(this.ApplyDate),
      };
      const url = `BudgetApply/SetStatus`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`提出申請成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async openModal(item) {
      var date = item.applyDate ? item.applyDate : new Date();
      this.ApplyNumber = item.ApplyNumber;
      this.ApplyDate = this.$twDate2(this.$moment(date).format("YYYY-MM-DD"));
      this.SeqNo = item.SeqNo;
      this.$bvModal.show("editModal");
    },
    async saveApplyNumberData(item) {
      const obj = {
        SeqNo: item.SeqNo,
        ApplyNumber: item.ApplyNumber,
        ApplyDate: item.ApplyDate,
        Supplement: item.Supplement,
      };
      const url = `BudgetApply/SetApplyNumber`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`公文號內容儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async saveApplyDateData(item) {
      const obj = {
        SeqNo: this.items[item.index].SeqNo,
        ApplyDate: this.$moment(item.data).format("YYYY-MM-DD"),
        ApplyNumber: this.items[item.index].ApplyNumber,
        Supplement: this.items[item.index].Supplement,
      };
      const url = `BudgetApply/SetApplyNumber`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`發文日期儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openSubsidyEdit(item) {
      store.editItem = JSON.parse(JSON.stringify(item));
      if (this.searchSemester == 1) {
        store.nowTableTitle = "待遇補助經費申請明細表";
        store.nowTab = 1;
        this.$router.push("/focusapplicationedit");
      } else {
        store.nowTableTitle = "待遇補助經費申請明細表";
        store.nowTab = 1;
        this.$router.push("/focusapplicationeditsemestertwo");
      }
    },
    openSingleSubEdit(item) {
      store.nowTab = 1;
      store.semester = this.searchSemester;
      store.editItem = JSON.parse(JSON.stringify(item));
      store.nowTableTitle = "學生申請清冊";
      this.$router.push("/localsignlesubapplicationedit");
    },
    openEquipPurchase(item) {
      store.nowTab = 1;
      store.semester = this.searchSemester;
      store.editItem = JSON.parse(JSON.stringify(item));
      store.nowTableTitle = "採購品目申請明細表";
      this.$bvModal.show("equipmentLocalPurchases");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async clearSearch() {
      this.searchSchool = null;
      this.searchYear = new Date().getFullYear() - 1911 - 1;
      this.searchSemester = 1;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.orderBy2 = [];
      if (this.searchSemester == 1) {
        this.headersOne.forEach((s) => {
          if (s.sortDesc !== null) {
            this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
          }
        });
      } else {
        this.headersTwo.forEach((s) => {
          if (s.sortDesc !== null) {
            this.orderBy2.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
          }
        });
      }

      this.getData();
    },
    openSubsidyEditprinted(item) {
      if (this.searchSemester == 1) {
        store.editItem = JSON.parse(JSON.stringify(item));
        store.nowTab = 1;
        store.nowTableTitle = "印(請)領清冊核銷明細表";
        this.$router.push("/focuswriteoffedit");
      } else {
        store.editItem = JSON.parse(JSON.stringify(item));
        store.nowTab = 1;
        store.nowTableTitle = "印(請)領清冊核銷明細表";
        this.$router.push("/focuswriteoffeditsemestertwo");
      }
    },
    async getData() {
      // odata3 qs top&skip

      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.searchSemester == 1 ? this.orderBy : this.orderBy2;
      !orderBy.length ? orderBy.push("Status desc") : "";
      const obj = orderBy.length ? { top, skip, orderBy } : { top, skip };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");

      let url = "";
      url = `BudgetApply/List${qs}&actor=${account}&sBYear=${this.searchYear}&sTerm=${this.searchSemester}&sCategory=T`;

      if (this.healthCodeCheck) {
        this.searchSchool = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      if (this.searchSchool) {
        url = `${url}&schoolcode=${this.searchSchool}`;
      }
      try {
        let { Items, Count } = await window.axios.get(url);
        Items = Items.map((s) => {
          s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "";

          s.applyDate = s.ApplyDate ? new Date(`${s.ApplyDate}`) : "";
          return s;
        });
        this.items = JSON.parse(JSON.stringify(Items));
        this.compontCalderKey += 1;
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    const currentMonth = new Date().getMonth() + 1; // 月份从0开始，需要加1

    // 判断月份范围并设置searchSemester
    if (currentMonth >= 1 && currentMonth <= 6) {
      this.searchSemester = 2; // 1到6月份，设置为第二学期
    } else {
      this.searchSemester = 1; // 7到12月份，设置为第一学期
    }
    this.compontCalderKey = 0;
    await this.getAllSchool();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchYear(v) { store.searchYear = v; },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header {
  //width: 1650px;
  display: grid;
  grid-template-columns: 60px 120px 150px 140px 160px 140px 180px 120px 120px repeat(4,140px);
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 40px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header-semester2 {
  grid-template-columns: 60px 120px 150px 140px 160px 140px 180px 120px 120px repeat(4,140px) ;
}
.content {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 220px 284px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
