<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

import TabOneSubsidyApplication from "./TabOneSubsidyApplication";
import TabTwoSubsidyApplication from "./TabTwoSubsidyApplication";
import TabThreeSubsidyApplication from "./TabThreeSubsidyApplication";
import TabSummerSubsidyApplication from "./TabSummerSubsidyApplication";
import TabFiveSubsidyApplication from "./TabFiveSubsidyApplication";
import TabSixSubsidyApplication from "./TabSixSubsidyApplication";
import TabSevenSubsidyApplication from "./TabSevenSubsidyApplication";
//import TabEightSubsidyApplication from "./TabEightSubsidyApplication";
export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「原住民族及離島地區醫事人員養成計畫公費生」",
      titles: [
        {
          title: "經費申請",
          comps: "TabOneSubsidyApplication",
          isActive: false,
          functionId: "20301",
        },
        /*{
          title: "在地養成-經費請領(撥付)",
          comps: "TabEightSubsidyApplication",
          isActive: false,
          functionId: "20031",
        },*/
        {
          title: "經費核銷",
          comps: "TabTwoSubsidyApplication",
          isActive: false,
          functionId: "20302",
        },
        {
          title: "各科系註冊費設定",
          comps: "TabThreeSubsidyApplication",
          isActive: false,
          functionId: "20303",
        },
        /*{
          title: "暑期課程學分費補助申請",
          comps: "TabSummerSubsidyApplication",
          isActive: false,
          functionId: "20011",
        },*/
        {
          title: "重點科別-經費預估(審核用)",
          comps: "TabFiveSubsidyApplication",
          isActive: false,
          functionId: "20305",
        },
        {
          title: "重點科別-預算核定(審核用)",
          comps: "TabSixSubsidyApplication",
          isActive: false,
          functionId: "20306",
        },
        /*{
          title: "暑期課程學分費補助核定",
          comps: "TabSevenSubsidyApplication",
          isActive: false,
          functionId: "20012",
        }*/
      ],
    };
  },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  components: {
    VueTabs,
    VTab,
    TabOneSubsidyApplication,
    TabTwoSubsidyApplication,
    TabThreeSubsidyApplication,
    TabSummerSubsidyApplication,
    TabFiveSubsidyApplication,
    TabSixSubsidyApplication,
    TabSevenSubsidyApplication,
    //TabEightSubsidyApplication,
  },
  methods: {
    setUserRight() {
      let arr = Boolean(sessionStorage.functionIds)
        ? JSON.parse(sessionStorage.getItem("functionIds"))
        : [];
      this.titles = this.titles.map((s) => {
        s.isActive = arr.find((k) => k == s.functionId) ? true : false;
        return s;
      });
      this.titles = this.titles.filter((s) => s.isActive);
    },
  },
  created() {},
  mounted() {
    if (this.nowTab > 1) {
      this.tabName = this.titles[this.nowTab-1].title;
    }
    this.setUserRight();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>