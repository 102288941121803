<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        經費預估清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="年度">
          <b-select
            :options="years"
            v-model="searchYear"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="學期">
          <b-select
            :options="[
              { value: 1, text: '第一學期(上學期)' },
              { value: 2, text: '第二學期(下學期)' },
            ]"
            v-model="searchSemester"
            @change="searchDb"
          ></b-select>
        </b-input-group>

        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
        <b-button variant="success" @click="batch">批次核定</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersOne"
        :key="`headersOne${i}`"
        :title="item"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="cursor: pointer;padding-top:3px;">
        {{ i + 1 }}
      </div>
      <div :title="item.status" style="padding:2px 0">
        <b-button
          variant="success"
          size="sm"
          @click="budgetPass(item)"
          class="mr-2"
          :disabled="item.StatusName != '預審中' ? true : false"
          >通過</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click="budgetDeny(item)"
          :disabled="item.StatusName != '預審中' ? true : false"
          >退件</b-button
        >
      </div>
      <div :title="item.servicenow" style="padding:2px 0">
        <b-button variant="primary" size="sm" @click="openPrintPage(item)"
          >檢視</b-button
        >
      </div>
      <!--<div :title="item.name" style="padding:2px 0">
        <b-button
          variant="primary"
          size="sm"
          @click="openPrintPage2(item)"
          >檢視</b-button
        >
      </div>-->
      <div
        :style="
          item.StatusName == '預審中'
            ? 'color:#43944a'
            : item.StatusName == '已退回'
            ? 'color:red'
            : item.StatusName == '預審完成'
            ? 'color:rgb(18 107 214)'
            : item.StatusName == '完成核定'
            ? 'color:rgb(18 107 214)'
            : ''
        "
        :id="`itemOne${i}`"
      >
        {{ item.StatusName || "" }}
      </div>

      <b-tooltip
        :target="`itemOne${i}`"
        placement="top"
        :variant="item.StatusName == '完成核定' ? 'primary' : 'danger'"
        style="text-align:left;min-width:300px"
        v-if="item.StatusName == '完成核定' || item.StatusName == '已退回'"
      >
        <div v-if="item.StatusName == '完成核定'" style="text-align: left;">
          完成核定<br />請於{{
            item.PerAuditComment ? $twDate4(item.PerAuditComment) : ""
          }}前函文
        </div>
        <div v-else style="text-align: left;">
          退回申請<br />原因:{{ item.RejectComment || "" }}
        </div>
      </b-tooltip>

      <div>{{ item.ApplyDate ? $twDate(item.ApplyDate) : "" }}</div>

      <div>{{ item.PassDate ? $twDate(item.PassDate) : "" }}</div>

      <div :title="item.school">{{ item.school || "" }}</div>

      <div :title="item.BYear">{{ item.BYear || "" }}</div>

      <div :title="item.Term">{{ item.Term == 1 ? "一" : item.Term == 2 ? "二" : "" }}</div>

      <div v-if="item.StatusName == '完成核定'">
      <div :title="item.Approve_Amt">
          {{ item.Approve_Amt ? $formatPrice(item.Approve_Amt) : "" }}
        </div>
      </div>
      <div v-else>
        <div :title="item.Apply_Amt">
          {{ item.Apply_Amt ? $formatPrice(item.Apply_Amt) : "" }}
        </div>
      </div>

      <div>{{ item.PreApproveDate ? $twDate(item.PreApproveDate) : "" }}</div>
      <div>{{ item.PreApproveNumber || "" }}</div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal
        id="editModal"
        :title="`${editKind == 1 ? '通過預審' : '預審退件'}`"
        size="md"
        :header-bg-variant="`${editKind == 1 ? 'success' : 'danger'}`"
      >
        <div class="equip-grid-2">
          <b-input-group prepend="培育學校">
            <b-input v-model="budgetSchoolName" disabled></b-input>
          </b-input-group>
          <b-input-group prepend="函文期限" class="mt-2" v-show="editKind == 1">
            <calendarInput v-model="budgetPassContent" />
          </b-input-group>
          <b-input-group prepend="退件原因" class="mt-2" v-show="editKind == 2">
            <b-form-textarea
              id="textarea"
              placeholder="退件原因輸入"
              rows="3"
              max-rows="6"
              v-model="budgetDenyReason"
            ></b-form-textarea>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveData"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="approveModel"
        title="核定日期及文號"
        size="sm"
      >
        <div>
          <b-input-group prepend="核定日期">
            <calendarInput
              v-model="approveDateSet"
              style="height:40px;text-align:center"
            />
          </b-input-group>
          <b-input-group prepend="核定文號">
            <b-input v-model="approveNumberSet"></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('approveModel')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="doApprove"
              >確定</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>
<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import calendarInput from "@/components/localCalendar.vue";

// import { countryObject } from "@/constant.js";

const headersOne = [
  { name: "序", key: "", sortDesc: null },
  { name: "審核", key: "", sortDesc: null },
  { name: "待遇補助經費申請表", key: "", sortDesc: null },
  //{ name: "待遇補助經費概算審定表", key: "", sortDesc: null },
  { name: "審核狀態", key: "Status", sortDesc: null },
  { name: "提出日期", key: "ApplyDate", sortDesc: null },
  { name: "初審日期", key: "PassDate", sortDesc: null },
  { name: "培育學校", key: "SchoolCode", sortDesc: null },
  { name: "年度", key: "BYear", sortDesc: null },
  { name: "學期", key: "Term", sortDesc: null },
  { name: "預估(核定)金額", key: "Apply_Amt", sortDesc: null },//Approve_Amt
  { name: "核定日期", key: "PreApproveDate", sortDesc: null },
  //{ name: "核定金額", key: "Approve_Amt", sortDesc: null },
  { name: "核定文號", key: "PreApproveNumber", sortDesc: null },
];

const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1905;
let years = new Array(9).fill().map((s, i) => year + 1 - i);

export default {
  name: "downarea",
  data() {
    return {
      headersOne,

      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      schoolObject: [],
      searchSchool: null,
      searchYear: new Date().getFullYear() - 1911 - 1,
      searchSemester: 1,

      editKind: 1,

      budgetSeqNumber: "",
      budgetSchoolName: "",
      budgetPassContent: new Date(),
      budgetDenyReason: "",

      approveDateSet: new Date(),
      approveNumberSet: "",
    };
  },
  components: { calendarInput },
  computed: {},

  methods: {
    async saveData() {
      //1是通過、2是退件
      let obj = {};
      let url = "";
      if (this.editKind == 1) {
        url = "BudgetApply/SetPerAudit";
        obj = {
          SeqNo: this.budgetSeqNumber,
          PerAuditComment: this.$moment(this.budgetPassContent).format(
            "YYYY-MM-DD"
          ),
        };
      } else if (this.editKind == 2) {
        url = "BudgetApply/SetReject";
        obj = {
          SeqNo: this.budgetSeqNumber,
          RejectComment: this.budgetDenyReason,
        };
      }
      const statusObj = {
        SeqNo: this.budgetSeqNumber,
        Status: this.editKind == 1 ? 8 : 5,
      };
      const statusUrl = `BudgetApply/SetStatus`;
      try {
        await window.axios.put(url, obj);
        await window.axios.put(statusUrl, statusObj);
        this.$bvToast.toast(`預審成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`預審失敗: ${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    budgetPass(item) {
      //this.$bvModal.show("editModal");
      this.editKind = 1;
      this.budgetSeqNumber = item.SeqNo;
      this.budgetSchoolName = item.school;
      this.saveData();
    },
    budgetDeny(item) {
      this.$bvModal.show("editModal");
      this.budgetDenyReason = "";
      this.editKind = 2;
      this.budgetSeqNumber = item.SeqNo;
      this.budgetSchoolName = item.school;
    },
    openPrintPage(item) {
      const url = `/#/doc4?schoolcode=${item.SchoolCode}&byear=${item.BYear}&term=${item.Term}&category=T`;
      window.open(url);
    },
    openPrintPage2(item) {
      const url = `/#/doc21?schoolcode=${item.SchoolCode}&byear=${item.BYear}&term=${item.Term}&category=T`;
      window.open(url);
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchSchool = null;
      this.searchYear = new Date().getFullYear() - 1911 - 1;
      this.searchSemester = 1;
      this.getData();
    },
    batch() {
      this.approveNumberSet = "";
      this.approveDateSet = new Date();
      this.$bvModal.show("approveModel");
    },
    async doApprove() {
      const number = this.approveNumberSet;
      if (!number || number.length < 1){
        this.$bvToast.toast(`請輸入核定文號`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }

      const date = this.approveDateSet;
      const content = $.map(this.items, function(item) {
        if (item.Status == 3 && !(item.PreApproveDate && item.PreApproveNumber)) {
          return {
            ApproveDate: date,
            SeqNo: item.SeqNo
          }
        }
      });
      if (content.length < 1) {
        this.$bvToast.toast(`無申請中資料`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "primary",
        });
        return;
      }

      const obj = {
        ApproveNumber: number,
        Content: content
      };
      await window.axios.put(`BudgetApply/PreApprove`, obj);
      this.$bvToast.toast(`核定成功`, {
        title: "系統資訊",
        autoHideDelay: 5000,
        variant: "success",
      });
      this.getData();
      this.$bvModal.hide("approveModel");
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    async getData() {
      // odata3 qs top&skip
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      let orderBy = this.orderBy;
      !orderBy.length ? orderBy.push("Status desc") : "";
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      const account = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");
      let url = "";
      url = `BudgetApply/List${qs}&actor=${account}&sBYear=${this.searchYear}&sTerm=${this.searchSemester}&sCategory=T`;
      if (this.searchSchool) {
        url = `${url}&schoolcode=${this.searchSchool}`;
      }

      try {
        let { Items, Count } = await window.axios.get(url);
        Items = Items.map((s) => {
          s.school = this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "";

          s.applyDate = s.ApplyDate ? new Date(`${s.ApplyDate}`) : "";
          return s;
        });
        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    const currentMonth = new Date().getMonth() + 1;
    if(currentMonth >= 1 && currentMonth <= 6){
      this.searchSemester = 2;
    }else{
      this.searchSemester = 1;
    }
    this.getAllSchool();
    this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 60px 150px 154px 168px 118px 140px 140px 190px 90px 140px 140px 140px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.dtc-grid-header4 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 220px 300px repeat(30, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
::v-deep#editModal___BV_modal_header_ {
  color: #fff;
}
</style>